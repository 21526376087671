import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { DialogContentNoClose, DialogHeader } from "@/components/ui/dialog";
import { HausButton } from "@/components/ui/hausbutton";
import { HausCheckbox } from "@/components/ui/hauscheckbox";
import { Input } from "@/components/ui/input";
import { SparklesCore } from "@/components/ui/sparkles";
import { cn } from "@/lib/utils";
import { Dialog, DialogDescription, DialogTitle } from "@radix-ui/react-dialog";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import logo from "app/assets/images/AmandaLogo.png";

import { ContainerScroll } from "@/components/ui/container-scroll-animation";
import {
  Form,
  isRouteErrorResponse,
  Link,
  useActionData,
  useLoaderData,
  useNavigate,
  useRouteError,
  useSearchParams,
} from "@remix-run/react";
import salesfunnel from "app/assets/images/SalesFunnel.png";
import movinglogo from "app/assets/images/svg/movinglogo.svg";
import { ArrowLeft, Check, Ellipsis, Rabbit, Terminal } from "lucide-react";
import { useEffect, useRef } from "react";
import { logFailedSignIn, logSignIn, verifyLogin } from "~/models/user.server";
import { createUserSession, getUserId } from "~/session.server";
import { safeRedirect, validateEmail } from "~/utils";


export const loader = async ({ request }: LoaderFunctionArgs) => {
  const userId = await getUserId(request);
  if (userId) return redirect("/");

  const LOGIN_HERO_MESSAGES = [
    "Crunch those numbers!",
    "Let the numbers do the talking",
    "Number crunchers’ heaven",
  ];
  const randommessage =
    LOGIN_HERO_MESSAGES[Math.floor(Math.random() * LOGIN_HERO_MESSAGES.length)];

  return json({
    randommessage,
  });
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const email = formData.get("email");
  const password = formData.get("password");
  const redirectTo = safeRedirect(formData.get("redirectTo"), "/");
  const remember = formData.get("remember");

  if (!validateEmail(email)) {
    return json(
      {
        errors: {
          email: "Email is invalid",
          password: null,
        },
      },
      {
        status: 400,
      },
    );
  }

  if (typeof password !== "string" || password.length === 0) {
    return json(
      {
        errors: {
          email: null,
          password: "Password is required",
        },
      },
      {
        status: 400,
      },
    );
  }

  if (password.length < 8) {
    return json(
      {
        errors: {
          email: null,
          password: "Password is too short",
        },
      },
      {
        status: 400,
      },
    );
  }

  const user = await verifyLogin(email, password);
  if (!user) {
    await logFailedSignIn({ username: email });
    return json(
      {
        errors: {
          email: "Invalid email or password",
          password: null,
        },
      },
      {
        status: 400,
      },
    );
  }
  console.log("🚀 ~ action ~ user:", user)

  logSignIn({ userId: user.id });

  return createUserSession({
    redirectTo,
    remember: remember === "on" ? true : false,
    request,
    userId: user.id,
  });
};

export const meta: MetaFunction = () => [
  {
    title: "Login",
  },
];

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const isSuccess = searchParams.get("success");
  const redirectTo = searchParams.get("redirectTo") || "/";
  const { randommessage } = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors?.password) {
      passwordRef.current?.focus();
    }
  }, [actionData]);

  return (
    <main className="bg-black h-full flex flex-col">
      <div className="bg-black h-16">
        <h1>
          <img
            src={logo}
            className="w-36 self-center py-6 translate-x-5"
            alt="Amanda AI logotype white capital letters"
          />
        </h1>
      </div>
      <div className="relative w-full grid grid-cols-1 mx-auto max-w-7xl items-center justify-center gap-12 lg:grid-cols-2 bg-black">
        <div className="absolute inset-0 z-10 w-full overflow-hidden">
          <SparklesCore
            id="tsparticlesfullpage"
            background="transparent"
            minSize={0.4}
            maxSize={2.0}
            particleDensity={70}
            className="h-screen w-full z-10"
            particleColor="#FFFFFF"
            speed={0}
          />
        </div>
        <div className="relative pt-12 lg:p-0">
          <div className="w-full px-8">
            <div className="relative z-50 mx-auto flex">
              {/* <div className="relative px-5 lg:px-12 z-50 mx-auto flex items-center gap-0 max-h-screen max-w-screen-xl xl:max-w-screen-xl"> */}
              {/* <div className="z-50 flex h-full w-1/2 flex-grow flex-col justify-center border"> */}
              <div className="z-50 flex break-keep text-pretty flex-col justify-center overflow-visible">
                <div className="mb-12 xl:w-[500px]">
                  <p className="mb-3 text-sm font-semibold uppercase tracking-widest text-white">
                    Login
                  </p>
                  <h1 className="tracking-tight text-hausorange xl:mb-2 text-6xl leading-[3rem] sm:leading-[4.4rem] md:text-[7rem] md:leading-[6rem] md:tracking-tight">
                    {randommessage}
                  </h1>
                </div>
                <div>
                  <Form method="post" className="mr-5 grid grid-cols-1 gap-5" viewTransition>
                    <section>
                      <div className="relative border-t border-white border-opacity-50 py-1">
                        <Input
                          ref={emailRef}
                          id="email"
                          required

                          autoFocus={true}
                          name="email"
                          type="email"
                          autoComplete="email"
                          aria-invalid={
                            actionData?.errors?.email ? true : undefined
                          }
                          aria-describedby="email-error"
                          className="peer w-full bg-black bg-opacity-50 px-2 py-3 text-lg font-light text-white placeholder:text-transparent focus:outline-none"
                          placeholder="Username"
                        />
                        {actionData?.errors?.email ? (
                          <div className="pt-1 text-red-700" id="email-error">
                            {actionData.errors.email}
                          </div>
                        ) : null}
                        <label
                          htmlFor="email"
                          className="absolute bottom-3 left-0 ml-1 -translate-y-4 px-1 text-sm font-light text-white duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:ml-1 peer-focus:-translate-y-7 peer-focus:bg-black peer-focus:px-1 peer-focus:text-xs peer-focus:text-white"
                        >
                          Username
                        </label>
                      </div>
                      <div className="relative border-b border-t border-white border-opacity-50 py-1">
                        <Input
                          id="password"
                          ref={passwordRef}
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          aria-invalid={
                            actionData?.errors?.password ? true : undefined
                          }
                          aria-describedby="password-error"
                          className="peer w-full bg-black bg-opacity-50 px-2 py-3 text-lg font-light text-white placeholder:text-transparent focus:outline-none"
                          placeholder="Password"
                        />
                        {actionData?.errors?.password ? (
                          <div className="pt-1 text-red-700" id="password-error">
                            {actionData.errors.password}
                          </div>
                        ) : null}
                        <label
                          htmlFor="password"
                          className="absolute bottom-3 left-0 ml-1 -translate-y-4 px-1 text-sm font-light text-white duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:ml-1 peer-focus:-translate-y-7 peer-focus:bg-black peer-focus:px-1 peer-focus:text-xs peer-focus:text-white"
                        >
                          Password
                        </label>
                      </div>
                    </section>
                    <Input type="hidden" name="redirectTo" value={redirectTo} />
                    <div className="flex items-center justify-between">
                      <div className="flex space-x-7">
                        <Button
                          type="submit"
                          size="sm"
                          className="rounded-[50px] bg-white py-0.5 text-xs font-semibold uppercase tracking-widest text-black hover:text-white hover:bg-hausorange transition-all delay-100 duration-500"
                        >
                          Login
                        </Button>
                        <div className="items-center hidden sm:flex ">
                          <HausCheckbox
                            id="remember"
                            name="remember"
                            style={{
                              border: "1px solid white", borderRadius: 0
                            }}
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          />
                          <label
                            htmlFor="remember"
                            className="ml-2 block text-white font-thin text-xs cursor-pointer"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="flex items-center justify-center">
                        <Link
                          viewTransition
                          className="text-white font-thin text-xs tracking-wider underline"
                          to={{
                            pathname: "/recover",
                            search: searchParams.toString(),
                          }}
                        >
                          {/* <HausButton className="text-nowrap text-hausorange text-xs p-0 m-0" variant="link"> */}
                          Forgot password?
                          {/* </HausButton> */}
                        </Link>
                      </div>
                    </div>
                    <div className="text-center text-sm text-white">
                      <div className="text-center text-sm text-gray-300 font-thin tracking-wide">
                        Don&apos;t have an account?{" "}
                        <Link
                          viewTransition
                          className="underline text-hausorange"
                          to={{
                            pathname: "/join",
                            search: searchParams.toString(),
                          }}
                        >
                          Sign up!
                        </Link>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              {/* <div className="z-30 -ml-6 flex h-screen flex-none xl:-ml-12 border"> */}
            </div>
          </div>
        </div>
        <div className="z-30 -mt-24 lg:mt-0 flex overflow-hidden relative">
          <ContainerScroll>
            <img
              className="z-20 lg:object-scale-down overflow-hidden rotate-90 lg:rotate-0 transition-all delay-100 duration-500 transform-gpu"
              src={salesfunnel}
              alt="Image of two hands holding a funnel"
            />
          </ContainerScroll>
        </div>
        <SuccessDialog open={!!isSuccess} />
      </div>
      <div className="bg-hausorange px-8 pt-20 pb-36 z-50 flex-grow">
        <div className="grid grid-cols-4 mx-auto max-w-7xl">
          <div className="col-span-4">
            <div className="py-8 border-t border-b border-black">
              <img src={movinglogo} alt="Amanda AI logo" className="w-24" />
            </div>
            <div className="border-b border-black py-7 grid gap-3">
              <h4 className="hauslabel text-xs">Our philosophy:</h4>
              <p className="text-5xl font-medium w-80">
                Dance to the algorithms!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black py-12">
        <p className="text-center text-white text-xs">
          © 2025 Amanda AI. All rights reserved.
        </p>
      </div>
    </main>
  );
}

export function ErrorBoundary() {
  const navigate = useNavigate();
  const error = useRouteError();

  if (error instanceof Error) {
    return (
      <Alert variant="destructive">
        <Terminal className="h-4 w-4" />
        <AlertTitle>An unexpected error occurred</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  if (!isRouteErrorResponse(error)) {
    return (
      <Alert variant="destructive">
        <Terminal className="h-4 w-4" />
        <AlertTitle>An unknown error occurred</AlertTitle>
      </Alert>
    );
  }

  if (error.status === 404) {
    return (
      <div className="w-full flex flex-col items-center justify-center gap-3 mt-5">
        <div className="pt-7 pb-20 flex flex-col items-center justify-start gap-3 text-center bg-hausbeigedark w-full">
          <p><Ellipsis size={48} /></p>
          <h2 className="leading-10">404</h2>
          <p className="font-light tracking-wider leading-6 sm:w-1/2 text-balance">
            Unfortunately the role you are looking for does not exist.
          </p>
          <div className="mt-5">
            <HausButton className="flex gap-1 items-center" size="sm" onClick={() => navigate(-1)}>
              <ArrowLeft size={16} />
              <span>Go back</span>
            </HausButton>
          </div>
        </div>
      </div>
    )
  }

  if (error.status === 429) {
    return (
      <div className="w-full flex flex-col items-center justify-center gap-3 mt-5">
        <div className="pt-7 pb-20 flex flex-col items-center justify-start gap-3 text-center bg-hausbeigedark w-full">
          <p><Rabbit size={48} /></p>
          <h2 className="leading-10">429</h2>
          <p className="font-light tracking-wider leading-6 sm:w-1/2 text-balance">
            Too many requests. Please try again later.
          </p>
          <div className="mt-5">
            <HausButton className="flex gap-1 items-center" size="sm" onClick={() => navigate(-1)}>
              <ArrowLeft size={16} />
              <span>Go back</span>
            </HausButton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Alert variant="destructive">
      <Terminal className="h-4 w-4" />
      <AlertTitle>An unexpected error occurred</AlertTitle>
      <AlertDescription>{error.statusText}</AlertDescription>
    </Alert>
  );
}


function SuccessDialog({ open }: { open: boolean }) {
  return (
    <Dialog open={open}>
      <DialogContentNoClose className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            <div className="flex justify-center mb-6">
              <div className={cn("rounded-full p-4 bg-hausspringgreen")}>
                <Check size={48} className="text-white" />
              </div>
            </div>
          </DialogTitle>
          <DialogDescription className="text-black">
            <div className="mb-3 mt-9 text-center">
              <h1 className="text-3xl font-semibold mb-2">Password Reset</h1>
              <p className="text-lg font-light">
                Your password has been successfully reset.
              </p>
            </div>
          </DialogDescription>
        </DialogHeader>
        <div className="w-full">
          <Link to="/login" viewTransition>
            <HausButton className="w-full">
              Back to login
            </HausButton>
          </Link>
        </div>
      </DialogContentNoClose>
    </Dialog>
  )
}